import { PostHog } from "posthog-js";

const CUSTOMER_ID_KEY = "tricorder-customer";

export function loaded(posthog_instance: PostHog){
  const customerId = posthog_instance.getFeatureFlag(CUSTOMER_ID_KEY, {send_event: false}) as string;

  if(!customerId) { //The customer is not logged in, we clear any Ids that might already exist from previous sessions.
    posthog_instance.reset();

  } else if(!posthog_instance._isIdentified()) { //The customer is logged in, but is not identified. We identify them.
    posthog_instance.identify(customerId);

  } else if(posthog_instance.get_distinct_id() != customerId) { //The customer is logged in, and is identified with a different Customer id than the current one. We identify them with the new id.
    posthog_instance.reset();
    posthog_instance.identify(customerId);

  }
  //The customer is logged in, and is correctly identified with the current customer ID
}